import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section, Strong, Icon, Span, List, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { GiHeartPlus } from "react-icons/gi";
import { FaHeart, FaMusic, FaPaintBrush, FaMobileAlt } from "react-icons/fa";
import { MdArrowForward } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Florence
			</title>
			<meta name={"description"} content={"Embark on a heartfelt journey through life and love in the story-driven game Florence."} />
			<meta property={"og:title"} content={"Florence"} />
			<meta property={"og:description"} content={"Embark on a heartfelt journey through life and love in the story-driven game Florence."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6729d77041a0cf0024a53ade/images/game-8-removebg-preview.png?v=2024-11-05T10:44:32.398Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6729d77041a0cf0024a53ade/images/game-8-removebg-preview.png?v=2024-11-05T10:44:32.398Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6729d77041a0cf0024a53ade/images/game-8-removebg-preview.png?v=2024-11-05T10:44:32.398Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6729d77041a0cf0024a53ade/images/game-8-removebg-preview.png?v=2024-11-05T10:44:32.398Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6729d77041a0cf0024a53ade/images/game-8-removebg-preview.png?v=2024-11-05T10:44:32.398Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6729d77041a0cf0024a53ade/images/game-8-removebg-preview.png?v=2024-11-05T10:44:32.398Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6729d77041a0cf0024a53ade/images/game-8-removebg-preview.png?v=2024-11-05T10:44:32.398Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="text" />
			<Override slot="quarklycommunityKitMobileSidePanel" />
			<Override slot="text1" />
		</Components.Header>
		<Section padding="180px 0 180px 0" background="linear-gradient(0deg,rgba(116, 12, 54, 0.5) 0%,rgba(116, 12, 54, 0.56) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6729d77041a0cf0024a53ade/images/game-3.jpg?v=2024-11-05T10:44:23.380Z) 50% 40% /cover repeat scroll padding-box" md-padding="100px 0 100px 0" quarkly-title="Content-12">
			<Override
				slot="SectionContent"
				flex-direction="row"
				align-items="center"
				justify-content="center"
				md-flex-direction="column"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				padding="0px 80px 0px 0px"
				width="50%"
				lg-width="70%"
				md-width="100%"
				md-padding="0px 0 0px 0px"
				md-margin="0px 0px 50px 0px"
			>
				<Text margin="0px 0px 10px 0px" font="normal 500 20px/1.5 --fontFamily-sans" color="white">
					Enjoy
				</Text>
				<Text margin="0px 0px 30px 0px" font="--headline1" color="white">
					Florence
				</Text>
				<Text margin="0px 0px 40px 0px" color="--light" font="--base">
					Embark on a heartfelt journey through life and love in the story-driven game Florence.
				</Text>
				<Button
					font="normal 400 16px/1.5 --fontFamily-sans"
					background="--color-dark"
					padding="12px 28px 12px 28px"
					type="link"
					href="https://play.google.com/store/apps/details?id=com.mountains.feathertop&hl=en"
					text-decoration-line="initial"
				>
					Download
				</Button>
			</Box>
			<Box
				min-width="250px"
				min-height="250px"
				width="250px"
				height="250px"
				background="--color-darkL1"
				border-radius="50%"
				display="flex"
				align-items="center"
				justify-content="center"
				flex-direction="column"
				md-align-self="flex-end"
			>
				<Image src="https://uploads.quarkly.io/6729d77041a0cf0024a53ade/images/game-8-removebg-preview.png?v=2024-11-05T10:44:32.398Z" srcSet="https://smartuploads.quarkly.io/6729d77041a0cf0024a53ade/images/game-8-removebg-preview.png?v=2024-11-05T10%3A44%3A32.398Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6729d77041a0cf0024a53ade/images/game-8-removebg-preview.png?v=2024-11-05T10%3A44%3A32.398Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6729d77041a0cf0024a53ade/images/game-8-removebg-preview.png?v=2024-11-05T10%3A44%3A32.398Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6729d77041a0cf0024a53ade/images/game-8-removebg-preview.png?v=2024-11-05T10%3A44%3A32.398Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6729d77041a0cf0024a53ade/images/game-8-removebg-preview.png?v=2024-11-05T10%3A44%3A32.398Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6729d77041a0cf0024a53ade/images/game-8-removebg-preview.png?v=2024-11-05T10%3A44%3A32.398Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6729d77041a0cf0024a53ade/images/game-8-removebg-preview.png?v=2024-11-05T10%3A44%3A32.398Z&quality=85&w=3200 3200w" sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw" />
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="60px 0 60px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			quarkly-title="Game Scenario"
			id="about"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 48px 0px"
				lg-justify-content="center"
			>
				<Text as="p" font="--base" color="--red" margin="0px 0px 8px 0px">
					<Strong font="--lead">
						Discover Florence's Story
					</Strong>
				</Text>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					lg-text-align="center"
				>
					What the Game is About
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--light"
					width="50%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					lg-text-align="center"
					lg-width="100%"
				>
					Florence is a narrative-driven game about relationships, personal growth, and the moments that shape us. Experience the highs and lows of love through beautiful, interactive storytelling.
				</Text>
			</Box>
			<List
				margin="40px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				as="ul"
				sm-margin="24px 0 0 0"
				list-style-type="none"
				font="normal normal 18px/150% sans-serif"
				display="grid"
				flex-direction="column"
				grid-gap="24px 32px"
				lg-margin="32px 0px 0px 0px"
				grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
			>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="gi"
						icon={GiHeartPlus}
						size="20px"
						color="--red"
						margin="5px 0 0 0"
					/>
					<Text padding="0" margin="0" color="--light">
						<Span font="normal 600 16px/1.5 --fontFamily-sans">
							Relationship Dynamics.{" "}
						</Span>
						<Span opacity="0.9" font="--base">
							Explore the beauty and challenges of love as Florence navigates the ups and downs of a new relationship.
						</Span>
					</Text>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="gi"
						icon={GiHeartPlus}
						size="20px"
						color="--red"
						margin="5px 0 0 0"
					/>
					<Text padding="0" margin="0" color="--light" font="18px/27px --fontFamily-sans">
						<Span font="normal 600 16px/1.5 --fontFamily-sans">
							Life Moments.{" "}
						</Span>
						<Span opacity="0.9" font="--base">
							Experience Florence's everyday life, from working a job to pursuing personal passions.
						</Span>
					</Text>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="gi"
						icon={GiHeartPlus}
						size="20px"
						color="--red"
						margin="5px 0 0 0"
					/>
					<Text padding="0" margin="0" font="18px/27px --fontFamily-sans" color="--light">
						<Span font="normal 600 16px/1.5 --fontFamily-sans">
							Interactive Storytelling.{" "}
						</Span>
						<Span opacity="0.9" font="--base">
							Use interactive elements to immerse yourself in Florence's world, from first dates to pivotal conversations.
						</Span>
					</Text>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="gi"
						icon={GiHeartPlus}
						size="20px"
						color="--red"
						margin="5px 0 0 0"
					/>
					<Text padding="0" margin="0" color="--light" font="18px/27px --fontFamily-sans">
						<Span font="normal 600 16px/1.5 --fontFamily-sans">
							Artistic Expression.{" "}
						</Span>
						<Span opacity="0.9" font="--base">
							Enjoy Florence's hand-drawn, expressive art style that brings each scene to life.
						</Span>
					</Text>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="gi"
						icon={GiHeartPlus}
						size="20px"
						color="--red"
						margin="5px 0 0 0"
					/>
					<Text padding="0" margin="0" color="--light" font="18px/27px --fontFamily-sans">
						<Span font="normal 600 16px/1.5 --fontFamily-sans">
							Musical Accompaniment.{" "}
						</Span>
						<Span opacity="0.9" font="--base">
							A stunning soundtrack enhances the emotional experience of the game.
						</Span>
					</Text>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="gi"
						icon={GiHeartPlus}
						size="20px"
						color="--red"
						margin="5px 0 0 0"
					/>
					<Text padding="0" margin="0" font="18px/27px --fontFamily-sans" color="--light">
						<Span font="normal 600 16px/1.5 --fontFamily-sans">
							Self-Discovery.{" "}
						</Span>
						<Span opacity="0.9" font="--base">
							Follow Florence's journey of growth and self-discovery, as she learns more about herself through love.
						</Span>
					</Text>
				</Box>
			</List>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="60px 0 60px 0"
			background="rgba(0, 0, 0, 0) linear-gradient(120deg, white 0%, --color-lightD2 100%) 0% 0% /auto repeat scroll padding-box"
			color="--dark"
			md-padding="60px 0 60px 0"
			quarkly-title="Benefits/Features"
			id="benefits"
		>
			<Override slot="SectionContent" max-width="1220px" md-align-items="center" sm-min-width="280px" />
			<Box
				border-radius="24px"
				margin="0px 0px 80px 0px"
				md-margin="0px 0px 40px 0px"
				width="55%"
				md-width="80%"
				sm-width="100%"
			>
				<Text
					md-text-align="center"
					margin="0px 0px 16px 0px"
					font="--base"
					color="--orange"
					letter-spacing="1px"
				>
					BENEFITS OF PLAYING
				</Text>
				<Text
					color="--greyD3"
					md-text-align="center"
					margin="0px 0px 0px 0px"
					font="--headline2"
					width="100%"
					letter-spacing="-1px"
					lg-width="100%"
					md-font="--headline3"
				>
					Why You'll Love Florence
				</Text>
			</Box>
			<Box
				lg-flex-wrap="wrap"
				display="grid"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="16px"
				md-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="repeat(1, 1fr)"
				sm-grid-gap="8px"
			>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
				>
					<Icon
						category="fa"
						icon={FaHeart}
						size="64px"
						margin="0px 0px 32px 0px"
						color="--orange"
						md-margin="0px 0px 16px 0px"
					/>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Emotional Storytelling
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						Experience a deeply moving story about relationships and personal growth.
					</Text>
				</Box>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
				>
					<Icon
						category="fa"
						icon={FaMusic}
						size="64px"
						margin="0px 0px 32px 0px"
						color="--orange"
						md-margin="0px 0px 16px 0px"
					/>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Beautiful Music
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						A carefully composed soundtrack that complements the emotional moments.
					</Text>
				</Box>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
				>
					<Icon
						category="fa"
						icon={FaPaintBrush}
						size="64px"
						margin="0px 0px 32px 0px"
						color="--orange"
						md-margin="0px 0px 16px 0px"
					/>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Unique Art Style
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						Florence's hand-drawn art style beautifully captures every scene.
					</Text>
				</Box>
				<Box
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-align-items="center"
				>
					<Icon
						category="fa"
						icon={FaMobileAlt}
						size="64px"
						margin="0px 0px 32px 0px"
						color="--orange"
						md-margin="0px 0px 16px 0px"
					/>
					<Text
						margin="0px 0px 18px 0px"
						color="--darkL2"
						font="--headline3"
						lg-text-align="left"
						md-font="--lead"
						md-text-align="center"
					>
						Perfect for Mobile
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD2"
						font="--base"
						lg-text-align="left"
						md-text-align="center"
					>
						Enjoy the smooth, accessible gameplay experience designed for mobile.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="60px 0" sm-padding="40px 0" background="--color-darkL2" quarkly-title="About-8">
			<Override slot="SectionContent" flex-direction="row" lg-flex-wrap="wrap" sm-min-width="100%" />
			<Box
				width="70%"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				padding="90px 64px 90px 64px"
				background="linear-gradient(0deg,rgba(0,0,0,0) 0%,rgba(116, 12, 54, 0.74) 0%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6729d77041a0cf0024a53ade/images/game-4.jpg?v=2024-11-05T10:44:23.371Z) 0% 0% /cover repeat scroll padding-box"
				lg-width="100%"
				sm-padding="32px 32px 32px 32px"
				lg-padding="48px 64px 90px 64px"
				md-padding="48px 48px 90px 48px"
			>
				<Box
					display="flex"
					flex="1 1 55%"
					flex-direction="column"
					justify-content="flex-start"
					lg-width="100%"
					lg-align-items="flex-start"
					lg-margin="0"
					padding="30px 0px 0 0"
					lg-flex-direction="row"
					lg-flex-wrap="wrap"
				>
					<Text
						as="h1"
						margin="0px"
						font="--headline2"
						color="--light"
						width="100%"
						md-width="100%"
						md-margin="0px 0px 26px 0px"
						md-padding="0px 0px 0px 0px"
						sm-font="normal 600 30px/1.2 --fontFamily-sans"
					>
						Join Florence's Journey
					</Text>
					<Text
						as="p"
						margin="1.2em 0 0 0"
						font="--headline3"
						font-size="1.2em"
						font-weight="300"
						color="--light"
						width="60%"
						md-width="100%"
						md-margin="0px 0px 0px 0px"
						md-padding="0px 0px 0px 0px"
						lg-max-width="640px"
					>
						Follow Florence as she discovers love, faces challenges, and learns more about herself in a beautifully crafted story.
					</Text>
					<Link
						href="https://play.google.com/store/apps/details?id=com.annapurnainteractive.florence"
						color="--light"
						margin="2em 0 0 0"
						font="500 1.1em --fontFamily-sans"
						text-decoration-line="initial"
						display="flex"
						grid-gap="4px"
						transition="all 0.3s ease"
						padding="0"
						justify-content="flex-start"
						flex="0 0 auto"
						order="0"
						align-self="flex-start"
						overflow-x="hidden"
						overflow-y="hidden"
						lg-margin="2em 0 0 0"
						align-items="flex-start"
						hover-border-bottom="1px solid #909dfd"
					>
						Download
						<Icon category="md" icon={MdArrowForward} size="1.1em" />
					</Link>
				</Box>
			</Box>
			<Box
				width="30%"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				position="relative"
				justify-content="center"
				lg-width="100%"
				lg-justify-content="flex-start"
				background="--color-primary"
			>
				<Image
					src="https://uploads.quarkly.io/6729d77041a0cf0024a53ade/images/game-6.jpg?v=2024-11-05T10:44:23.369Z"
					display="block"
					max-height="80%"
					position="absolute"
					left="-200px"
					width="400px"
					height="400px"
					object-fit="cover"
					object-position="0% 100%"
					lg-position="static"
					lg-width="100%"
					lg-height="auto"
					lg-max-height="600px"
					sm-max-height="400px"
					transform="skewY(-10deg)"
					srcSet="https://smartuploads.quarkly.io/6729d77041a0cf0024a53ade/images/game-6.jpg?v=2024-11-05T10%3A44%3A23.369Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6729d77041a0cf0024a53ade/images/game-6.jpg?v=2024-11-05T10%3A44%3A23.369Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6729d77041a0cf0024a53ade/images/game-6.jpg?v=2024-11-05T10%3A44%3A23.369Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6729d77041a0cf0024a53ade/images/game-6.jpg?v=2024-11-05T10%3A44%3A23.369Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6729d77041a0cf0024a53ade/images/game-6.jpg?v=2024-11-05T10%3A44%3A23.369Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6729d77041a0cf0024a53ade/images/game-6.jpg?v=2024-11-05T10%3A44%3A23.369Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6729d77041a0cf0024a53ade/images/game-6.jpg?v=2024-11-05T10%3A44%3A23.369Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="--color-light" quarkly-title="FAQ-10" id="faq">
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 600 36px/1.2 --fontFamily-sans" color="--darkL1">
						Frequently Asked Questions
					</Text>
					<Text
						margin="0px 0px 70px 0px"
						font="normal 300 20px/1.5 --fontFamily-sansHelvetica"
						color="#555a5f"
						sm-margin="0px 0px 50px 0px"
						lg-margin="0px 0px 50px 0px"
					>
						Here are the most frequently asked questions about Florence and the{" "}
						<Span color="#ff005e" font="500 20px/30px Frutiger, &quot;Frutiger Linotype&quot;, Univers, Calibri, &quot;Gill Sans&quot;, &quot;Gill Sans MT&quot;, &quot;Myriad Pro&quot;, Myriad, &quot;DejaVu Sans Condensed&quot;, &quot;Liberation Sans&quot;, &quot;Nimbus Sans L&quot;, Tahoma, Geneva, &quot;Helvetica Neue&quot;, Helvetica, Arial, sans-serif">
							answers
						</Span>
						{" "}to them.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="block"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="50px 50px"
					md-grid-template-columns="1fr"
					sm-grid-gap="35px 0"
					padding="0px 0px 0px 70px"
					lg-padding="0px 0px 0px 0"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							What is the gameplay like in Florence?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Florence offers interactive storytelling, allowing players to explore life events through mini-games and simple mechanics.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Is Florence a long game?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							The game is relatively short, offering a concise experience that can be completed in around 30-45 minutes.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							What makes Florence unique?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Its focus on emotions, relationships, and personal growth sets it apart from other games, creating a deeply engaging experience.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Does Florence have a soundtrack?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Yes, it features a beautiful soundtrack that complements the emotional storytelling of the game.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer2 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66fbc4301b94b10024315de0"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});